import * as React from 'react';
export default class HubspotForm extends React.Component<{ formId: string; portalId: string; id: string; }> {
    componentDidMount() {
        const { formId, portalId } = this.props;
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: portalId,
                    formId: formId,
                    target: `#hubspot-form-${this.props.formId}`
                });
            }
        });
    }
    render() {
        return (
            <div>
                <div id={`hubspot-form-${this.props.formId}`} />
            </div>
        );
    }
}
