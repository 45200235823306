import { graphql, StaticQuery } from "gatsby";
import * as React from "react";
import "./catalyst-cloud.scss";
import "../../styles/shared.scss";
import {
  getPage,
  TypeCatalystCloudPage,
  TypeCatalystCloudQueryResult,
} from "../../utils/queries/catalyst_cloud";
import AmazeePage from "../../components/layout";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import usePreviewData from "../../utils/usePreviewData";
import HubspotForm from "../../components/hubspot-form";

const query = graphql`
  {
    allPrismicCatalystCloud {
      edges {
        node {
          data {
            page_meta_description
            page_meta_thumbnail {
              url
              alt
            }
            title
            meta_title
            hero_image {
              alt
              url
            }
            hero_text {
              html
              raw
              text
            }
            hero_subtitle
            highlights_group {
              highlight_title
              highlight_text {
                html
                raw
                text
              }
              icon {
                alt
                url
              }
            }
            form_featured_description {
              html
              raw
              text
            }
            hubspot_form_title
            hubspot_form_portal_id
            hubspot_form_id
          }
        }
      }
    }
  }
`;

const CatalystCloudQueryPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeCatalystCloudQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicGetStarted",
    true
  ) as TypeCatalystCloudPage;
  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="catalyst_cloud">
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={RichTextRender(result.hero_text)}
          image={result.hero_image}
          subheader={result.hero_subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="highlights">
          <div className="container">
            <div id="form-anchor"></div>
            <div className="row items-center">
              {result.highlights_group.map((highlight, index) => (
                <div className="col-12 col-lg-4 highlight" key={index}>
                  <img src={highlight.icon.url} alt={highlight.icon.alt} />
                  <div className="service-title">
                    {highlight.highlight_title}
                  </div>
                  <div className="description">
                    {RichTextRender(highlight.highlight_text)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="form_section">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10">
                <div className="form-inner block md:flex justify-between">
                  <div className="text-side col-12 col-lg-5">
                    {RichTextRender(result.form_featured_description)}
                  </div>
                  <div className="form-side col-12 col-lg-6">
                    <HubspotForm
                      key="2912"
                      formId={result.hubspot_form_id}
                      portalId={result.hubspot_form_portal_id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticCatalystCloudQueryPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <CatalystCloudQueryPage location={location} data={data} />
      )}
    />
  );
};
export default StaticCatalystCloudQueryPage;
