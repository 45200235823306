import { PrismicImage, PrismicRichText } from "./types";
import { graphql } from "gatsby";

export type TypeHighlightsGroup = {
  icon: PrismicImage;
  highlight_title: string;
  highlight_text: PrismicRichText;
};

export type TypeCatalystCloudPage = {
  title: string;
  meta_title: string;
  hero_text: PrismicRichText;
  hero_subtitle: string;
  hero_image: PrismicImage;
  page_meta_description: string;
  page_meta_thumbnail: PrismicImage;

  form_featured_description: PrismicRichText;
  hubspot_form_title: string;
  hubspot_form_portal_id: string;
  hubspot_form_id: string;

  highlights_group: TypeHighlightsGroup[];
};

export type TypeCatalystCloudQueryResult = {
  allPrismicCatalystCloud: {
    edges: Array<{
      node: { data: TypeCatalystCloudPage };
    }>;
  };
};

export const getPage = (
  data: TypeCatalystCloudQueryResult
): TypeCatalystCloudPage => {
  return data.allPrismicCatalystCloud.edges[0].node.data;
};
